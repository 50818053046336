<template>
  <v-app id="login">
    <app-snack-bar />
    <v-main class="login-page">
      <v-container
        fluid
        fill-height
      >
        <v-layout
          align-center
          justify-center
        >
          <v-flex
            xs12
            sm4
            md4
            lg3
          >
            <v-card
              class="app-card app-card--without-border pa-3 login-page-card"
              max-width="365"
            >
              <v-form
                ref="form"
                lazy-validation
                class="login-page-form app-form"
                @submit.prevent="onSubmit"
              >
                <v-card-text class="pt-0 pb-0">
                  <div class="layout column align-center">
                    <img
                      src="../../assets/images/logo.svg"
                      alt="Logo Sistransce"
                      class="login-page-form__logo"
                    >
                    <h5 class="login-page-form__title mb-5">
                      Digite seu email abaixo para resetar sua senha
                    </h5>
                  </div>
                  <v-text-field
                    v-model="formModel.username"
                    prepend-inner-icon="person"
                    label="E-mail *"
                    type="text"
                    outlined
                    :rules="[rules.required, rules.email]"
                    color="#067B26"
                  />
                </v-card-text>
                <v-card-actions class="pt-0">
                  <v-btn
                    block
                    x-large
                    depressed
                    type="submit"
                    color="#067B26"
                    class="login-page-form__submit white--text"
                    :loading="loading"
                  >
                    Continuar
                  </v-btn>
                  <div
                    v-if="error"
                    class="alert alert-danger"
                  >
                    {{ error }}
                  </div>
                </v-card-actions>

                <router-link
                  :to="{name: 'login'}"
                  title="Voltar login"
                  class="back"
                >
                  Voltar login
                </router-link>
                <section
                  v-if="loading"
                  class="loading"
                />
              </v-form>
              <v-progress-linear
                :active="loading"
                :indeterminate="loading"
                absolute
                bottom
                color="#067B26"
              />
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import Vue from 'vue';
  import GlobalStore from '@/store/modules/global';
  import AppSnackBar from '@/components/AppSnackBar';
  import AuthenticationService from '@/services/authentication.service';

  import { required } from 'vuelidate/lib/validators';
  import { throwError } from 'rxjs';

  import { APP_NAME } from '@/commons/config';

  export default Vue.extend({
    components: {
      AppSnackBar
    },
    data: () => ({
      loading: false,
      appName: APP_NAME,
      formModel: {
        username: '',
      },
      rules: {
        required: value => !!value || 'Esse campo deve ser preenchido',
        email: value => {
          var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(value) || 'E-mail fora do padrão';
        }
      },
      error: '',
      returnUrl: ''
    }),
    created() {
      this.returnUrl = this.$route.query.returnUrl || '/';
    },
    methods: {
      onSubmit() {
        const isValid = this.$refs.form.validate();

        if (isValid) {
          this.loading = true;
          const { username } = this.formModel;
          AuthenticationService.forgotPassword(
            username,
          ).subscribe(
            response => {
              GlobalStore.setSnackBar({
                timeout: 10000,
                show: true,
                message: 'Recebemos sua solicitação. Em breve irá receber um email com instruções para resetar sua senha.',
                type: 'success'
              })
              this.loading = false;
              this.$router.push({name: 'login'})
            }, error => {
              //throwError(error);
              this.loading = false;
            }
          );
        }
      }
    }
  });
</script>

<style scoped lang="scss">
#login {
  .login-page {
    display: flex;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("../../assets/images/background.png") #f1f1f1;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-attachment: fixed;
    background-size: contain;

    &-card {
      padding: 30px;
      margin: auto;
    }

    &-form {
      margin-top: 10px;
      margin-bottom: 10px !important;

      &__logo {
        width: 82%;
        margin: 0px auto 20px auto;
        display: table;
      }

      &__title {
        text-align: center;
        font-weight: 400;
        margin: 5px auto;
      }

      &__appname {
        margin: 0px;
        font-weight: bold !important;
        font-size: 20px !important;
      }
    }
    .text-right {
      display: table;
      margin-right: 0;
      width: 100%;
    }
  }
}
.back{
  margin: 10px auto;
  display: table;
  text-transform: capitalize;
}
</style>
